import React from 'react';
import './header.scss';
import logo from '../../assets/nav_logo.png';
import openseaIcon from '../../assets/opensea-logo.svg';
import config from '../../web3/config.json';
import {Link} from "react-router-dom";

const Header = ({ account, status }) => {

  return (
    <>
      <div className='main_header_div'>
        <div className='inner_head_div'>
          <div className='heading'><img src={logo} alt='logo'/></div>
          <div className='w-address-div'>
            {/* <Link to={config.REDIRECT_URL} target='_blank' ><img src={openseaIcon} height={30} width={30}/></Link>*/}
            <button className='sign_in_btn'>
              { status === "connecting" || status === "initializing" ? 'initializing...' : `${account?.slice(0, 5)}...${account?.slice(-5)}`}</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;